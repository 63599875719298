<template>
  <data-list
      ref="dataList"
      class="content md"
      url="/apply-temp"
      query="&sort=id&filter[is_oa]=2"
      entity-name="业务"
      add-btn="新增业务"
      hide-show-detail
      :action="action"
      :rules="rules"
      :columns="columns"
      :before-update="beforeUpdate"
      :search-key-type="{name: 1}">
    <template v-slot:query="{form}">
      <a-form-model-item label="">
        <a-input v-model="form.name" placeholder="输入业务名称查询"></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:action="{item}">
      <router-link :to="{name: 'Process', params: {id: item.id}}" class="txt-btn" style="margin-left: 8px" @click="setProcess(item)">流程设置</router-link>
    </template>
    <template v-slot:edit="{form}">
      <a-form-model-item label="业务名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入业务名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="业务类型" prop="type">
        <a-select
            style="width: 100%"
            placeholder="请选择业务类型"
            :options="hrTempTypes"
            v-model="form.type">
        </a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import {hrTempTypes} from "@/common/constant/template";
import {getTypeForm} from "@/common/hr/form";

export default {
  name: "HRProcess",
  data() {
    return {
      hrTempTypes,
      columns: [
        {title: '业务名称', dataIndex: 'name'},
      ],
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        type: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
      }
    }
  },
  computed: {
    action() {
      return this.$route.meta.action;
    }
  },
  methods: {
    setProcess() {

    },
    beforeUpdate(form, callback) {
      form.is_oa = 2;
      let type = form.type;
      switch (type) {
        case 1:
          form.detail = JSON.stringify(getTypeForm("monk"))
          break;
        case 2:
          form.detail = JSON.stringify(getTypeForm("um"))
          break;
        case 3:
          form.detail = JSON.stringify(getTypeForm("board"))
          break;
        case 4:
          form.detail = JSON.stringify(getTypeForm("ub"))
          break;
        case 5:
          form.detail = JSON.stringify(getTypeForm("trans"))
          break;
      }
      callback(form);
    }
  }
}
</script>

<style scoped>

</style>
